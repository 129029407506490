/* You can add global styles to this file, and also import other style files */

:root {
  --fontFamily: 'Red Hat Display', sans-serif;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: var(--fontFamily);
}